import * as Sentry from '@sentry/nextjs';
import { OfferFragment } from '@codegen/gatewayUtils';
import {
  DohopServiceBase,
  OfferResponse,
  OfferResponseChargeTransactionsItem,
} from '@codegen/offerAPI';
import { getIatasFromRoutes } from '@utils/itineraryUtils';

export const getIatasFromOffer = ({
  itinerary: { homebound, outbound },
}: OfferFragment) => {
  return [...getIatasFromRoutes(outbound), ...getIatasFromRoutes(homebound)];
};

export const getIatasFromOffers = (offers: OfferFragment[]) => {
  const iatas = offers.reduce<string[]>(
    (acc, offer) => [...acc, ...getIatasFromOffer(offer)],
    [],
  );

  return [...new Set(iatas)];
};

export const modifyTransferUrl = (
  url: string,
  _newBookingFlowEnvironments?: string[],
) => {
  // const runtimeEnv = process.env.NEXT_PUBLIC_RUNTIME_ENV || RUNTIME_ENV.dev;

  // if (runtimeEnv === RUNTIME_ENV.production || typeof window === 'undefined') {
  //   return url;
  // }

  try {
    const urlObj = new URL(url);

    // const combo = urlObj.searchParams.get('combo');

    // if (!combo || !newBookingFlowEnvironments?.includes(runtimeEnv)) {
    //   return url;
    // }

    if (urlObj.pathname.includes('fares')) {
      return `${urlObj.pathname}?${urlObj.searchParams.toString()}`;
    }

    const language = urlObj.searchParams.get('lang');

    const newPathname = language ? `/${language}/fares` : '/fares';

    /* eslint-disable functional/immutable-data */
    urlObj.pathname = newPathname;
    urlObj.protocol = window.location.protocol;
    urlObj.host = window.location.host;
    /* eslint-enable functional/immutable-data */

    return urlObj.toString();
  } catch (error) {
    Sentry.captureMessage(`Invalid URL: ${url}`);

    return url;
  }
};

export const transformUTMParam = (utmParam?: string | string[]) =>
  Array.isArray(utmParam) ? utmParam[0] : utmParam;

export const getDohopServiceClassFromOfferSummary = (
  summary: OfferResponse['summary'] | undefined,
) =>
  summary?.other_services.find(
    (service): service is DohopServiceBase =>
      service.service_class === 'dohop_commission_fee' ||
      service.service_class === 'dohop_service_fee',
  )?.service_class ?? null;

export const chargeTransactionItemIsDohopConnectionService = (
  item: OfferResponseChargeTransactionsItem,
  dohopServiceClass?: 'dohop_commission_fee' | 'dohop_service_fee',
) => {
  const carrier = 'carrier' in item ? item.carrier : null;

  return !carrier && dohopServiceClass === 'dohop_service_fee';
};
